import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    
*,
*::before,
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}
html {
    font-family: "Oswald", sans-serif;
    scroll-behavior: smooth;
    background: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.white};


}

a{
    text-decoration: none;
    color:  ${(props) => props.theme.colors.white};
    transition: all 250ms ease-in-out;
    
    &:hover{
        color: ${(props) => props.theme.colors.main};
    }
}

ul {
    list-style: none;
}



.container {
  width: 80%;
  margin: 0 auto;
}

.containerMd {
  width: 60%;
  margin: 0 auto;
}

.btn {
  width: max-content;
  display: inline-block;
  padding: 1.2rem 1.5rem;
  border: 2px solid transparent;

  background: ${(props) => props.theme.colors.orange};
  border-radius: 0.6rem;
  cursor: pointer;
  transition: all 400ms ease-in-out;
         @media (max-width: 900px) {
  
    width: 100%;

  }
}

.btn:hover {
  border: 2px solid ${(props) => props.theme.colors.orange};
  background: transparent;

}

`;
