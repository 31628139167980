import styled from "styled-components";

const FooterWrapper = styled.footer`
  width: 100%;
  height: max-content;
  text-align: center;
  align-items: center;

  .footerLogo {
    margin-bottom: 3rem;
    display: inline-block;
    @media (max-width: 900px) {
      margin-bottom: 1.5rem;
    }
    img {
      width: 10rem;
      @media (max-width: 900px) {
        width: 5rem;
      }
    }
  }

  .footerContainer ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
    color: ${(props) => props.theme.colors.orange};

    @media (max-width: 900px) {
      margin: 0 auto 1rem;
    }
  }

  .footerCopyright {
    color: ${(props) => props.theme.colors.purple};
    margin-bottom: 1rem;
  }
`;

const Footer = ({ children }) => {
  return <FooterWrapper>{children}</FooterWrapper>;
};

export default Footer;
