import styled from "styled-components";

export const HeaderWrapper = styled.header`
  width: 100%;
  color: white;
  position: relative;
  top: 0;
  @media (max-width: 900px) {
    margin-bottom: 5rem;
    top: 5rem;
  }

  & .headerCover {
    width: 100%;
  }
  .headerText {
    text-align: center;
    font-size: 3rem;
    color: rgba(186, 113, 62, 0.6);
    padding: 2rem;

    @media (max-width: 900px) {
      font-size: 1rem;
      padding: 0.5rem;
    }

    p {
      font-family: "Sacramento", system-ui;

      color: rgba(254, 252, 255, 0.8);
      text-shadow: 3px 3px 1px rgba(186, 113, 62, 0.4);
    }
  }
`;

const Header = ({ children }) => {
  return <HeaderWrapper>{children}</HeaderWrapper>;
};

export default Header;
