import styled from "styled-components";

const AboutWrapper = styled.div`
  width: 100%;
  margin-bottom: 10rem;
  color: ${(props) => props.theme.colors.gray};

  @media (max-width: 900px) {
    margin-bottom: 5rem;
  }

  & .aboutContainer {
    display: grid;
    grid-template-columns: 50% 40%;
    gap: 10%;
    align-items: center;
    padding-top: 8rem;
    font-size: 1.3rem;
    @media (max-width: 900px) {
      font-size: small;
      grid-template-columns: 1fr;
      gap: 3%;
      padding-top: 3rem;
    }
  }
  .aboutContent {
    text-align: justify;
    @media (max-width: 900px) {
      text-align: center;
    }
  }

  .aboutContent h2 {
    color: ${(props) => props.theme.colors.orange};
    font-size: 3rem;
    margin-bottom: 1.2rem;
    @media (max-width: 900px) {
      font-size: 2rem;
      text-align: center;
    }
  }

  .aboutBtn {
    margin-top: 2rem;
  }

  .aboutBtn span {
    color: ${(props) => props.theme.colors.black};
    font-size: large;
  }

  .aboutBtn span:hover {
    color: ${(props) => props.theme.colors.orange};
  }

  .aboutImage {
    box-shadow: 15px 15px 1px rgba(186, 113, 62, 0.2);
    @media (max-width: 900px) {
      width: 100%;
      margin-top: 1rem;
      box-shadow: 10px 10px 0.5px rgba(186, 113, 62, 0.2);
    }
  }
`;

const About = ({ children }) => {
  return <AboutWrapper>{children}</AboutWrapper>;
};

export default About;
