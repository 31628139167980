import styled from "styled-components";

const NavWrapper = styled.div`
  width: 100%;
  /* height: max-content; */
  background: rgba(232, 208, 174, 0.7);
  backdrop-filter: blur(2px);
  z-index: 5;
  position: absolute;
  top: 0;
  padding: 0.2rem 4rem;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 900px) {
    padding: 0 4rem 0.5rem;
    justify-content: center;
  }

  & .navContainer {
    display: flex;
    align-items: center;
    gap: 20rem;
    @media (max-width: 900px) {
      display: block;
    }

    & span {
      img {
        @media (max-width: 900px) {
          width: 50%;
          display: flex;
          margin: 0 auto;
        }
      }
    }

    & ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 3rem;
      @media (max-width: 900px) {
        gap: 1.5rem;
      }
    }

    & a {
      font-size: 1.5rem;
      color: ${(props) => props.theme.colors.orange};

      @media (max-width: 900px) {
        font-size: small;
      }
    }

    & a:hover {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

const Nav = ({ children }) => {
  return <NavWrapper>{children}</NavWrapper>;
};

export default Nav;
