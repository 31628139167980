import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./Components/Styles/GlobalStyle";
import Header from "./Components/Header/Header";
import bg from "./assets/bg.jpg";
import logo from "./assets/logo.png";
import djLogo from "./assets/Dj logo-colored.png";
import About from "./Components/About/About";
import product from "./assets/product.JPG";
import Contact from "./Components/Contact/Contact";
// import { ImPhoneHangUp } from "react-icons/im";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaTelegramPlane } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";
import Footer from "./Components/Footer/Footer";
import Nav from "./Components/Nav/Nav";
import { Modal } from "./Components/Modal/Modal";

const App = () => {
  const theme = {
    colors: {
      white: "#fefcff",
      black: "#000000",
      main: "#e8d0ae",
      gray: "#3b3835",
      orange: "#ba713e",
      purple: "#21125b",
    },
    mobile: "768px",
    transition: "all 658ms ease-in-out",
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    console.log(showModal);
    setShowModal((prev) => !prev);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Modal showModal={showModal} setShowModal={setShowModal} />
        <Nav>
          <div className="navContainer">
            <span>
              {" "}
              <img className="headerCover" src={logo} alt="logo"></img>
            </span>

            <ul>
              <li>
                <a href="#header">Home</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>

              <li>
                <a href="#contact">Contact</a>
              </li>

              {/* <li>
                <a
                  href="/products.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Products
                </a>
              </li> */}
            </ul>
          </div>
        </Nav>
        {/* ====================================Header Section=================================== */}
        <Header>
          <div id="header">
            <span>
              {" "}
              <img className="headerCover" src={bg} alt="home-design"></img>
            </span>
            <div className="headerText">
              <h1>Home Decor</h1>
              <p>enfold your eyes in gentle whispers</p>
            </div>
          </div>
        </Header>
        {/* ====================================About Section=================================== */}
        <About>
          <div id="about" className="container aboutContainer">
            <div className="aboutContent">
              <h2>About Us</h2>
              <p>
                MY name is Nasrin Javahery and I’m so glad you’re hear!
                <br />
                Javaherydesign specializes in handcrafted marble statement
                pieces for the contemporary living space. Every individual stone
                is carefully curated and has its own unique and mesmerizing
                characteristics. Our gallery is located in Tehran, Aghdasie
                where we can meet you there.
              </p>
              <button onClick={openModal} className="btn aboutBtn">
                <span>Get in touch</span>
              </button>
            </div>
            <img src={product} alt="product-img" className="aboutImage" />
          </div>
        </About>
        {/* ====================================Contact Section=================================== */}
        <Contact>
          <div id="contact" className="containerMd contactContainer">
            <h2>Contact Us</h2>
            <section className="contactContent">
              <article className="contactOption">
                <FaTelegramPlane color="#ba713e" size={30} />
                <h4>Telegram</h4>
                <h5>Nasrin Javahery</h5>
                <a
                  href="
https://t.me/javaherydesign"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Join us
                </a>
              </article>

              <article className="contactOption">
                <AiOutlineInstagram color="#ba713e" size={30} />
                <h4>Instagram</h4>
                <h5>javaherydesign</h5>
                <a
                  href="https://www.instagram.com/javaherydesign?igsh=MTRjeGhrandjd3dag%3D%3D&utm_source=qr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Follow us
                </a>
              </article>
              <article className="contactOption">
                <FaPinterestP color="#ba713e" size={30} />
                <h4>Pintrest</h4>
                <h5>Nasrinjawahery</h5>
                <a
                  href="https://www.pinterest.com/nasrinjawahery/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit us
                </a>
              </article>
            </section>
          </div>
        </Contact>
        {/* ====================================Footer Section=================================== */}
        <Footer>
          <footer className="footerContainer">
            <a href="#header" className="footerLogo">
              <img src={djLogo} alt="me" className="djLogo" />
            </a>

            <ul>
              <li>
                <a href="#header">Home</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>

              <li>
                <a href="#contact">Contact</a>
              </li>

              {/* <li>
                <a
                  href="/products.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Products
                </a>
              </li> */}
            </ul>

            <div className="footerCopyright">
              <small>&copy; Design by NivNet Group</small>
            </div>
          </footer>
        </Footer>
      </ThemeProvider>
    </>
  );
};

export default App;
