import styled from "styled-components";

const ContactWrapper = styled.div`
  width: 100%;
  /* height: max-content;  */
  background: ${(props) => props.theme.colors.gray};
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 5rem;
  padding: 5rem 0;

  @media (max-width: 900px) {
    margin-bottom: 2rem;
    padding: 3rem 0;
  }

  .contactContent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1%;
    @media (max-width: 900px) {
      grid-template-columns: 1fr;
    }
  }

  .contactContainer h2 {
    color: ${(props) => props.theme.colors.orange};
    font-size: 3rem;
    margin-bottom: 5rem;
    @media (max-width: 900px) {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      text-align: center;
    }
  }

  .contactOption {
    background: ${(props) => props.theme.colors.main};
    border: 0.1rem solid transparent;
    border-radius: 3rem;
    padding: 3rem;
    text-align: center;
    transition: all 500ms ease-in-out;

    & h4 {
      margin: 1rem 0;
    }

    & h5 {
      margin-bottom: 2rem;
    }

    & a {
      color: ${(props) => props.theme.colors.orange};
    }

    @media (max-width: 900px) {
      font-size: small;
      padding: 1.5rem;

      h4 {
        margin: 0.3rem 0;
      }

      h5 {
        margin-bottom: 0.5rem;
      }
    }
  }

  .contactOption:hover {
    background: transparent;
    border-color: ${(props) => props.theme.colors.main};
  }
`;

const Contact = ({ children }) => {
  return <ContactWrapper>{children}</ContactWrapper>;
};

export default Contact;
